<template>
  <section class="about" style="background: #FFF; position: relative;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner"
      >
    </v-img>
    <v-container  grid-list-md>
      <v-layout wrap mt-6 mb-12>
        <v-flex xs12 sm7 md6 lg6 pa-0 pr-12 class="tnf-hr-left">
          <div class="tnf-hr-text">
            <div>
              <h3>JION US</h3>
              <h5>如果你</h5>
              <h5>能准确拿捏用户的喜好</h5>
              <h5>能专业地让用户精准地选择合适的产品</h5>
              <h5>请把你的简历发给我们</h5>
              <h5><a href="mailto:tryba@126.com" target="_blank">tryba@126.com</a></h5>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 sm5 md6 lg6>
          <v-img
            :height="imgHeight"
            :width="imgWidth"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/hr_right.png"
            contain
            :position="position"
          ></v-img>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 6,
    innerWidth: window.innerWidth,
    imgPosition: 'left',
    imgWidth: 'auto',
    imgHeight: 'auto'
  }),
  created () {
    let position = window.innerWidth > 600 ? 'left' : 'center'
    let imgWidth = window.innerWidth > 600 ? 'auto' : '100%'
    let imgHeight = window.innerWidth > 600 ? '541' : 'auto'
    this.position = position
    this.imgWidth = imgWidth
    this.imgHeight = imgHeight
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '../css/hr.css';
</style>
